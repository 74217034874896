import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    margin: 0

  },
};

function TextInput(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <TextField styles={{height: '100px'}}
               id="outlined-multiline-flexible"
                 label="Paste textfile here"
                 multiline
                 rowsMax="8"
                 rows="8"
                 inputProps={{
                   style: {fontFamily: 'monospace'}
                 }}
                 className={classes.root}
                 margin="normal"
                 variant="filled"
                value={props.text}
                onChange={e => props.handleTextInput(e.target.value)}
              />
    </Paper>
  );
}

export default withStyles(styles)(TextInput);
