import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PrismCode from 'react-prism';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  root: {
    flexGrow: 1,
    height: 400,
    overflow: 'auto',
    fontSize: 12
  },
};


class JavaCode extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
      const { classes } = this.props;

      //const javaCode = "public class Test { public String stuff() { return \"banan\"; \n\n\n\n\n}";
      const javaCode = this.props.state.javaCode;
      return (
       <div style={{fontSize: 12}}>
        <PrismCode className="language-java" component="pre"  >
             {javaCode}
        </PrismCode>
       </div>
      );
    }

}

JavaCode.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JavaCode);
