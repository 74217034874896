import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    height: '200px',
    overflow: 'scroll',
    marginTop: 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});


function SimpleTable(props) {
  const { classes } = props;

  const data = props.state.tableDataJS;
  //alert("data"+JSON.stringify(data));

  return (
    <div >
        <div className={classes.root}>
          <Table className={classes.table}>
            <TableBody>
              {data.map((row,index) => (
                <TableRow key={index} style={{height: 10}}>
                    {row.map((col, index2) =>
                        <TableCell key={index2} align="left">{col}</TableCell>
                    )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
    </div>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);