import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

function Description(props) {
  const { classes } = props;

  return (
    <div>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          Text file parser
        </Typography>
        <Typography component="p">
          An AI algorithm will try to parse your text file and create a Java program that
          can parse this file and files like it. This may or may not work as expected
          so you will have to check and test the resulting program by hand.
        </Typography>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(Description);