import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import AppBar from '../components/AppBar.js'
import TextInput from '../components/TextInput.js'
import TabContainer from '../components/TabContainer.js'
import Table from '../components/Table.js'
import Description from '../components/Description.js'
import Examples from '../components/Examples.js'
import example0 from '../examples/example0.js'
import JavaCode from '../components/JavaCode.js'
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    textAlign: 'center',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  smalltitle: {
    marginTop: 10,
    marginBotton: 5,
    fontSize: '20px'
  }
});

class Index extends React.Component {
  state = {
    ...example0
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  componentDidMount() {

  }

  handleExample = data => {
      if(this.state.worker) {
          this.state.worker.terminate();
      }
      this.setState({worker: undefined})
      this.setState({ ...data });
  }

  handleTextInput = text => {
    this.setState({text: text});

    if(this.state.worker) {
        this.state.worker.terminate();
    }

    var myWorker = new Worker('worker.js');
    this.setState({worker: myWorker})
    myWorker.onmessage = this.onWorkerMessage;
    myWorker.postMessage(text);
  }

  onWorkerMessage = e => {
    console.log('Message received from worker');
    this.state.worker.terminate();
    this.setState({worker: undefined});
    const parserGeneratorResult = e.data;
    this.setState({ ...parserGeneratorResult });
    const s = {...parserGeneratorResult};
    s.text = this.state.text;
    console.log(JSON.stringify(s));
    window.gtag('event', 'conversion', {'send_to': 'AW-855488533/mu7_CNyYmZ0BEJXw9pcD'});
  }


  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <AppBar/>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginTop: 10}}>

            <div style={{flexGrow: 1, margin: 10}}><Examples handleExample={this.handleExample}/></div>
            <div style={{display: 'flex', flexDirection: 'column', flexGrow: 8}}>
                <div style={{justifyContent: 'right', display: 'flex', flexDirection: 'row'}}><Typography className={classes.smalltitle} color="inherit">Input</Typography></div>
                <div style={{flexGrow: 1}}><TextInput handleTextInput={this.handleTextInput} text={this.state.text}/></div>

                {this.state.worker ? <div><CircularProgress className={classes.progress} /></div>: '' }
                <div style={{justifyContent: 'right', display: 'flex', flexDirection: 'row'}}><Typography className={classes.smalltitle} color="inherit">Output</Typography></div>
                <div><TabContainer state={this.state}/></div>
            </div>
            <div style={{flexGrow: 1, width:'200px', margin: 10}}><Description/></div>
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));
