import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import example1 from '../examples/example1.js'
import example2 from '../examples/example2.js'
import example3 from '../examples/example3.js'
import example4 from '../examples/example4.js'
import example5 from '../examples/example5.js'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '360px',
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20
  },
    secondheading: {
      fontWeight: 'bold',
      fontSize: 16
    },
  listitem: {
    paddingTop: '0px',
    paddingBottom: '0px'
  }
});

function Examples(props) {
  const { classes } = props;
  return (
    <List component="nav" className={classes.root}>
      <ListItem>
        <ListItemText primary="Examples" classes = {{primary: classes.heading}}/>
      </ListItem>

      <Divider />
        <ListItem>
            <ListItemText primary="Plain text formats" classes = {{primary: classes.secondheading}}/>
        </ListItem>

      <ListItem button classes = {{root: classes.listitem}} onClick={event => props.handleExample(example1)}>
        <ListItemText primary="Popular Names" />
      </ListItem>
      <ListItem button classes = {{root: classes.listitem}} onClick={event => props.handleExample(example2)}>
        <ListItemText primary="Coordinates" />
      </ListItem>
      <ListItem button classes = {{root: classes.listitem}} onClick={event => props.handleExample(example3)}>
        <ListItemText primary="Simple log" />
      </ListItem>

        <ListItem>
          <ListItemText primary="XML" classes = {{primary: classes.secondheading}}/>
        </ListItem>
          <ListItem button classes = {{root: classes.listitem}} onClick={event => props.handleExample(example4)}>
            <ListItemText primary="Xml file" />
          </ListItem>

        <ListItem>
            <ListItemText primary="JSON" classes = {{primary: classes.secondheading}}/>
        </ListItem>
          <ListItem button classes = {{root: classes.listitem}} onClick={event => props.handleExample(example5)}>
            <ListItemText primary="Json file" />
          </ListItem>
    </List>
  );
}

export default withStyles(styles)(Examples);